/**
 * @description Dealerwebsites - Taxation
 *
 */

//
import Kentekenplaat from 'kentekenplaat'

import openIframe from '@src/utils/openIframe'
import { bodyScrollIntoView } from '@src/utils/scrollTo'

const CreateNewTaxationBlock = ($root: HTMLElement) => {
  if ($root) {
    const $inputNumberPlate: HTMLInputElement = $root.querySelector('#number_plate')
    const $inputDistance: HTMLInputElement = $root.querySelector('#distance')
    const $btn: HTMLButtonElement = $root.querySelector('.js-submit')

    const $jumpLink: HTMLAnchorElement = document.querySelector('.js-jumpto-taxation')

    if ($jumpLink) {
      $jumpLink.addEventListener('click', (e: any) => {
        e.preventDefault()

        bodyScrollIntoView(document.querySelector('.js-taxation-block'))
      })
    }

    // @ts-ignore
    let numberPlateFormatter = null
    let numberPlateValid: boolean = false

    const isFormValid = () => {
      return (
        numberPlateValid && $inputDistance.value.length > 2 && !isNaN(Number($inputDistance.value))
      )
    }

    const inputChangeHandler = () => {
      $btn.disabled = !isFormValid()
    }

    const numberPlateChangeHandler = (event: Event) => {
      // Determine if the numberplate is valid based on the event name.
      numberPlateValid = event.type === 'kentekenplaat.valid'
    }

    const formSubmitHandler = (event: any) => {
      event.preventDefault()

      removeEventListeners()

      $btn.disabled = true

      const $anchor: HTMLAnchorElement = document.createElement('a')
      const $form = event.currentTarget
      $anchor.href = `${$form.dataset.formsHost}/taxatie.html?inruilKenteken=${$inputNumberPlate.value}&inruilKMStand=${$inputDistance.value}`
      const iframeUrl = encodeURIComponent($anchor.href)

      openIframe(iframeUrl)

      setTimeout(() => {
        setEventListeners()

        $btn.disabled = false
      }, 1000)
    }

    const setEventListeners = () => {
      removeEventListeners()

      // Listeners for number plate formatter
      numberPlateFormatter = new Kentekenplaat($inputNumberPlate)
      $inputNumberPlate.addEventListener('kentekenplaat.valid', numberPlateChangeHandler)
      $inputNumberPlate.addEventListener('kentekenplaat.invalid', numberPlateChangeHandler)

      $inputNumberPlate.addEventListener('keyup', inputChangeHandler)
      $inputDistance.addEventListener('keyup', inputChangeHandler)

      $inputNumberPlate.addEventListener('change', inputChangeHandler)
      $inputDistance.addEventListener('change', inputChangeHandler)
    }

    const removeEventListeners = () => {
      // Listeners for number plate formatter
      numberPlateFormatter = null
      $inputNumberPlate.removeEventListener('kentekenplaat.valid', numberPlateChangeHandler)
      $inputNumberPlate.removeEventListener('kentekenplaat.invalid', numberPlateChangeHandler)

      $inputNumberPlate.removeEventListener('keyup', inputChangeHandler)
      $inputDistance.removeEventListener('keyup', inputChangeHandler)

      $inputNumberPlate.removeEventListener('change', inputChangeHandler)
      $inputDistance.removeEventListener('change', inputChangeHandler)

      $root.addEventListener('submit', formSubmitHandler)
    }

    setEventListeners()
  }
}

//
;(() => {
  const $root = [...document.querySelectorAll('.js-taxation')]
  $root.map(($item: HTMLElement) => CreateNewTaxationBlock($item))
})()
