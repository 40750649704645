/***
 * @name Overlay gallery - Gallery (duh!)
 *
 */

import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'

import useInterval from '@src/hooks/useInterval'

export interface IGallery {
  items?: any[]
  data?: any[]
  id: string
}

const Gallery: React.FC<IGallery> = ({ data, id, items }) => {
  //
  // Component state
  const $slider = useRef(null)
  const $thumbnailGroup = useRef(null)
  const [slide, setSlide] = useState(0)
  const [gallery, setGallery] = useState(0)
  const [autoUpdating, setAutoUpdate] = useState(true)

  // Slick carousel properties
  const settings: any = {
    arrows: window.innerWidth >= 768,
    beforeChange: (current: number, next: number) => {
      // Update UI when a slide changes
      if (current !== next) {
        const galleryId: string = data[next].galleryId
        const galleryIndex: number = items.map((item: any) => item.id).indexOf(galleryId)

        setGallery(galleryIndex)
        setSlide(next)
      }
    },
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  }

  //
  // Private event handlers
  const galleryClickHandler = (event: any) => {
    event.preventDefault()

    const galleryIndex: number = Number(event.currentTarget.dataset.index)
    const galleryId: string = items[galleryIndex].id

    if (galleryIndex !== gallery) {
      const image: any = data.find((item) => item.galleryId === galleryId)
      const slideIndex: number = data.map((item: any) => item.id).indexOf(image.id)

      setGallery(galleryIndex)
      setAutoUpdate(false)

      $slider.current.slickGoTo(slideIndex)
    }
  }

  const thumbnailClickHandler = (event: any) => {
    const slideIndex: number = Number(event.currentTarget.dataset.index)

    const galleryId: string = data[slideIndex].galleryId
    const galleryIndex: number = items.map((item: any) => item.id).indexOf(galleryId)

    setGallery(galleryIndex)
    setAutoUpdate(false)

    $slider.current.slickGoTo(slideIndex)
  }

  //
  // Hooks
  useEffect(() => {
    const slideIndex: number = data.map((item) => item.id).indexOf(id)

    $slider.current.slickGoTo(slideIndex)
  }, [])

  useEffect(() => {
    requestAnimationFrame(() => {
      // Reposition thumbnail if off screen
      const $activeImage: HTMLElement = $thumbnailGroup.current.querySelector('.is-active-image')
      const boundGroup: any = $thumbnailGroup.current.getBoundingClientRect()
      const boundThumbnail: any = $activeImage.getBoundingClientRect()

      if (!(boundThumbnail.left >= 0 && boundThumbnail.right <= boundGroup.width)) {
        requestAnimationFrame(() => {
          $thumbnailGroup.current.scrollLeft +=
            boundThumbnail.left - boundGroup.width + boundThumbnail.width
        })
      }
    })
  }, [slide])

  useInterval(
    () => {
      $slider.current.slickNext()
    },
    autoUpdating ? 5000 : null
  )

  // We have to cache the gallery category id so we know where to add the breaks
  let category: string = items[0].id

  //
  // Render
  return (
    <>
      <Slider {...settings} ref={$slider}>
        {data &&
          data.map((item: any, index: number) => (
            <div key={`item-${index}`}>
              <img src={item.imageMainUrl} />
            </div>
          ))}
      </Slider>
      <div className="gallery-toolbar">
        {items && items.length > 1 && (
          <div className="category-group">
            <ul className="list is-categorynav">
              {items.map((item, index) => (
                <li
                  key={`item-${index}`}
                  className={index === gallery ? 'list__item is-active' : 'list__item'}
                >
                  <a href="#" onClick={galleryClickHandler} data-index={index}>
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="thumbnail-group" ref={$thumbnailGroup}>
          <div className="thumbnail-group__container">
            {data &&
              data.map((item: any, index: number) => {
                const galleryId: string = items[gallery].id
                const isGalleryActive: boolean = item.galleryId === galleryId
                let className: string = 'thumbnail-item'
                if (slide === index) {
                  className += ' is-active-image'
                }
                if (isGalleryActive) {
                  className += ' is-active-gallery'
                }

                if (category !== item.galleryId) {
                  className += ' is-first-in-category'
                  category = item.galleryId
                }

                return (
                  <a
                    className={className}
                    key={`item-${index}`}
                    data-index={index}
                    onClick={thumbnailClickHandler}
                  >
                    <img src={item.imagePreviewUrl} />
                  </a>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(Gallery)
