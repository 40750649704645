/***
 * @name Used Cars - render component
 *
 */

import React from 'react'
import { createRoot } from 'react-dom/client'
import { ISellerInfoItem } from './SellerInfoItem'
import UsedCarsRelated from './UsedCarsRelated'
import UsedCarsTeaser from './UsedCarsTeaser'

const renderComponent = (
  $container: HTMLElement,
  data: any,
  dealerUrl: string,
  isPrivateLease: boolean,
  sellerInfo: ISellerInfoItem = null,
  showInfo: boolean = true
) => {
  if ($container) {
    const root = createRoot($container)
    if (sellerInfo) {
      root.render(
        <UsedCarsTeaser
          data={data}
          dealerUrl={dealerUrl}
          isPrivateLease={isPrivateLease}
          sellerInfo={sellerInfo}
          showInfo={showInfo}
        />
      )
    } else {
      root.render(<UsedCarsRelated dealerUrl={dealerUrl} data={data} />)
    }
  }
}

export default renderComponent
