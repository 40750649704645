/**
 * @name Navigation
 *
 * @description
 *
 */

import scrollLock from '@src/utils/scrollLock'
import viewport, {
  BREAKPOINT_CHANGE,
  BREAKPOINT_DESKTOP,
  BREAKPOINT_MOBILE,
} from '@src/utils/viewport'

const $root: HTMLElement = document.querySelector('#navigation')

if ($root) {
  // Cache DOM elements
  const $menu: HTMLElement = $root.querySelector('.menu')
  const $dropMenu: HTMLElement = document.querySelector('.js-menu')
  const $toggleMenu: HTMLAnchorElement = $root.querySelector('.toggle-menu')
  const $toggleMenuIcon: HTMLElement = $toggleMenu.querySelector('.icon')
  const $links: HTMLAnchorElement[] = [].slice.call($root.querySelectorAll('.toggle-collapser'))
  const $dim: HTMLElement = $root.querySelector('.js-dim')
  const $current: HTMLElement[] = [].slice.call($root.querySelectorAll('.current'))

  const lockedClassName = 'header-locked'

  let $active: HTMLAnchorElement

  let mobileMenuOpen: boolean = false
  let desktopMenuOpen: boolean = false

  //
  //
  // Helper function: Update arrow based upon
  const updateArrow = (target: HTMLAnchorElement) => {
    if (target && target.parentElement) {
      const isOpen = target.parentElement.classList.contains('active')

      target.querySelector('.icon').classList.toggle('icon-chevron-down', !isOpen)
      target.querySelector('.icon').classList.toggle('icon-chevron-up', isOpen)
    }
  }

  // Helper function: Update mobile menu
  const toggleMenu = (value: boolean) => {
    mobileMenuOpen = value

    $menu.classList.toggle('active', mobileMenuOpen)

    const menuItemActive = $menu.classList.contains('active')
    $toggleMenuIcon.classList.toggle('icon-menu', !menuItemActive)
    $toggleMenuIcon.classList.toggle('icon-remove', menuItemActive)

    scrollLock($dropMenu, mobileMenuOpen, lockedClassName)
  }

  // Helper function: Toggle menu item
  const toggleMenuItem = (target: HTMLAnchorElement) => {
    // Clicking the same item will update the menu
    if ($active === target) {
      $active.parentElement.classList.toggle('active')

      updateArrow($active)

      const itemActive: boolean = $active.parentElement.classList.contains('active')

      // Only toggle the scroll lock if the mobile menu is not open
      if (!mobileMenuOpen) {
        desktopMenuOpen = itemActive
        scrollLock($dropMenu, desktopMenuOpen, lockedClassName)
      }

      // Toggle background - using condition as doesn't work in IE11
      // https://til.hashrocket.com/posts/mnm3du0kja-classlisttoggle-2nd-arg-doesnt-work-in-ie11
      if (itemActive) {
        $root.classList.add('show-bg')
      } else {
        $root.classList.remove('show-bg')
      }

      return
    }

    // Update current menu item
    if ($active) {
      $active.parentElement.classList.toggle('active', false)
      updateArrow($active)
    }

    // Update new menu item
    $active = target
    $active.parentElement.classList.toggle('active', true)
    scrollLock($dropMenu, $active.parentElement.classList.contains('active'), lockedClassName)
    updateArrow($active)

    $root.classList.add('show-bg')
  }

  //
  // Add event listener to toggle mobile menu
  if ($menu && $toggleMenu) {
    $toggleMenu.addEventListener('click', (e) => {
      e.preventDefault()

      toggleMenu(!mobileMenuOpen)
    })
  }

  // Add clickable dim background to close
  if ($dim) {
    $dim.addEventListener('click', () => {
      toggleMenuItem($active)
    })
  }

  //
  // Toggle menu item
  $links.map(($el) => {
    if ($el.getAttribute('href') === '#') {
      $el.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault()

        toggleMenuItem(e.currentTarget as HTMLAnchorElement)
      })
    }
  })

  //
  // Viewport event listener to enable/disable scroll locking depending on which menu is open
  viewport.addListener(BREAKPOINT_CHANGE, (media: string) => {
    if (media === BREAKPOINT_DESKTOP && mobileMenuOpen) {
      scrollLock($dropMenu, false, lockedClassName)
    }
    if (media === BREAKPOINT_MOBILE && mobileMenuOpen) {
      scrollLock($dropMenu, true, lockedClassName)
    }
  })

  // TDS-11: Add selected state after 5 seconds as per old site
  if ($current.length > 0) {
    setTimeout(() => {
      $current.map((item: HTMLElement) => {
        item.classList.add('animate')
      })
    }, 5000)
  }
}
