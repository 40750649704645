/***
 * @name Modal appointment - bootstrapper
 *
 */

// Local imports
import Modal from '@src/components/uikit/modal'
import openIframe from '@src/utils/openIframe'

import fetchDealers from './fetchDealers'

//
// == Global declaration for google API
declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    service_appointment_api_url: string
  }
}

// Due to last minute backend refactoring I have to add a local variable here
let $activeAppointmentLink: HTMLAnchorElement

//
const $appointmentLinks = [...document.querySelectorAll<HTMLAnchorElement>('.js-appointment-link')]

if ($appointmentLinks.length > 0 && window.service_appointment_api_url) {
  // Private event handler: On initialize -> Fetch dealers, set submit click handler and trigger
  // modal window popup on startup
  const modalInitializedHandler = ($modal: HTMLDivElement) => {
    const $btnSubmit: HTMLButtonElement = $modal.querySelector(
      '.btn-action-primary'
    ) as HTMLButtonElement

    // Fetch API Url for loading dealers
    const apiUrl = window.service_appointment_api_url
    let location: string = ''

    //
    // Open location handler
    const openLocationHandler = () => {
      if (location) {
        const iframeUrl = encodeURIComponent(`${$activeAppointmentLink.href}${location}`)
        openIframe(iframeUrl)
        setTimeout(() => {
          modal.hide()
        }, 500)
      }
    }

    const locationChangeHandler = (value: string = null) => {
      location = value

      if (value) {
        $btnSubmit.removeAttribute('disabled')
      } else {
        $btnSubmit.setAttribute('disabled', 'disabled')
      }
    }

    $btnSubmit.addEventListener('click', (e) => {
      e.preventDefault()

      openLocationHandler()
    })

    //
    fetchDealers(apiUrl, $modal, locationChangeHandler)
  }

  const templateId = 'modal-appointment'
  const modal = Modal(templateId, modalInitializedHandler)

  $appointmentLinks.map((item) => {
    item.addEventListener('click', (e) => {
      e.preventDefault()

      $activeAppointmentLink = item

      console.log($activeAppointmentLink)

      modal.show()
    })
  })
}
