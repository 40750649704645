/***
 * @name Used Cars - Seller Info Item component
 *
 */

import React from 'react'

export interface ISellerInfoItem {
  email?: string
  name: string
  position: string
  imageUrl?: string
  phone?: string
  tagline: string
  title: string
}

const SellerInfoItem: React.FC<ISellerInfoItem> = ({
  email,
  imageUrl,
  name,
  phone,
  position,
  tagline,
  title,
}) => (
  <div className="item-seller-info">
    <div className="item-seller-info__intro">
      <h3>
        <span>{name}</span> {position}
      </h3>
      {imageUrl && (
        <div className="item-seller-info__intro-image">
          <img src={imageUrl} alt={name} />
        </div>
      )}
    </div>
    <div className="item-seller-info__body">
      <h4>{title}</h4>
      <p>{tagline}</p>
      <ul>
        {phone && (
          <li>
            <a href={`tel:${phone}`}>
              <i className="icon icon-phone" />
              <span>{phone}</span>
            </a>
          </li>
        )}
        {email && (
          <li>
            <a href={`mailto:${email}`}>
              <i className="icon icon-email" />
              <span>{email}</span>
            </a>
          </li>
        )}
      </ul>
    </div>
  </div>
)

export default SellerInfoItem
