import routie from '@src/utils/routie.js'

const openIframe = (iframeUrl: string) => {
  const pushUrl = `/box-iframe/${iframeUrl}/size=fullscreen`

  //@ts-ignore
  routie.navigate(pushUrl)
}

export default openIframe
