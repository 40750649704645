/**
 * @description For all promo popup, on each close button click ->
 * trigger close checkbox that closes popup
 *
 */

;(() => {
  const $items = [...document.querySelectorAll('.js-popup-btn-close')]

  $items.map(($item: HTMLButtonElement) => {
    $item.addEventListener('click', () => {
      const $checkbox: HTMLInputElement = document.querySelector($item.dataset.popupTarget)
      if ($checkbox) {
        $checkbox.checked = false
      }
    })
  })
})()
