import routie from '@src/utils/routie.js'
import { hide, show } from '@src/components/dealerwebsite/overlay/render'
import React from 'react'
import isURLWhitelisted from '@src/components/dealerwebsite/overlay-iframe/isURLWhitelisted'
import CookieSettings from '@src/components/dealerwebsite/overlay-cookiesettings/CookieSettings'

routie({
  '/': () => {
    hide()
  },
  '/box-iframe/:src/:args?': (src: string) => {
    const url = decodeURIComponent(src)
    console.log(url)

    if (isURLWhitelisted(url)) {
      const isYoutube = url.includes('youtube')
      show(
        <iframe
          className={`overlay__body-iframe ${isYoutube ? 'is-youtube' : ''}`}
          frameBorder={0}
          allowFullScreen={true}
          src={url}
        />
      )
    } else {
      alert('Oeps, verkeerde afslag! Deze pagina bestaat helaas niet meer.')
    }
  },
  'cookie-instellingen': () => {
    show(<CookieSettings />)
  },
})
