/***
 * @name Modal factory
 *
 */

// Main elements needed to bootstrap component
const $root: HTMLElement = document.querySelector('.js-modal')

//
//
const Modal = (templateId: string, onInitialize: any = null, onClose: any = null): any => {
  const $template: HTMLScriptElement = document.querySelector(`#${templateId}`)

  const showModalInit = (): boolean => $root !== null && $template !== null

  //
  if (showModalInit()) {
    let firstOpen: boolean = true
    let $modal: HTMLElement = null
    let $close: HTMLElement[] = null

    const isNotAlreadyOpen = () => $modal && !$modal.classList.contains('show')

    //
    // Private event handlers
    const closeClickHandler = (e: Event) => {
      e.preventDefault()

      closeModal()
    }

    //
    // Private methods
    const setModalEventHandlers = () => {
      clearModalEventHandlers()

      $close.map((item) => {
        item.addEventListener('click', closeClickHandler)
      })
    }

    const clearModalEventHandlers = () => {
      $close.map((item) => {
        item.removeEventListener('click', closeClickHandler)
      })
    }

    const openModal = () => {
      $modal.classList.toggle('show', true)
      setModalEventHandlers()
    }

    const closeModal = () => {
      clearModalEventHandlers()

      $modal.classList.toggle('show', false)

      onClose?.()
    }

    const renderModalHTML = () => {
      $root.innerHTML = $template.innerText
      $modal = $root.querySelector('.modal')
    }

    const setupModal = () => {
      renderModalHTML()
      $close = [...$modal.querySelectorAll<HTMLElement>('[data-dismiss="modal"]')]

      if (onInitialize !== null) {
        onInitialize($modal)
      }

      show()
    }

    const show = () => {
      if (firstOpen) {
        firstOpen = false

        setupModal()
      } else if (isNotAlreadyOpen()) {
        requestAnimationFrame(openModal)
      }
    }

    const hide = () => {
      if ($modal && $modal.classList.contains('show')) {
        closeModal()
      }
    }

    return { show, hide }
  }

  return null
}

export default Modal
