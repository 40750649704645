/***
 * @name Review Summary Row Item
 *
 */

import React from 'react'

import { numberFormatter } from '@src/utils/number-formatter'

export interface IReviewSummaryRowItem {
  label: string
  score: string
}

const ReviewSummaryRowItem: React.FC<IReviewSummaryRowItem> = ({ label, score }) => {
  return (
    <li className="review-summary__item">
      <span className="review-summary__item-label">{label}</span>
      <span className="review-summary__item-bar">
        <i style={{ width: `${Number(score) * 10}%` }} />
      </span>
      <span className="review-summary__item-amount">{numberFormatter.format(+score)}</span>
    </li>
  )
}

export default ReviewSummaryRowItem
