/***
 * @name Overlay iFrame - white list URL checker
 *
 */

interface IToyotaSettings {
  iframeDomainWhiteList?: string[]
  consentLevel?: number
}

interface IToyota {
  settings: IToyotaSettings
}

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    T1: IToyota
  }
}

const { iframeDomainWhiteList } = window.T1.settings

const isURLWhitelisted = (target: string) => {
  if (iframeDomainWhiteList.length === 0) {
    return false
  }

  const anchor: HTMLAnchorElement = document.createElement('a')
  anchor.href = target.toLowerCase()

  return (
    iframeDomainWhiteList.filter((domain) => anchor.hostname === domain.toLowerCase()).length === 1
  )
}

export default isURLWhitelisted
