/**
 * @description Filter list used in News & Promos List view
 *
 */

import dropdown from '@src/components/uikit/dropdown'

const $form: HTMLFormElement = document.querySelector('.js-form-filter')

if ($form) {
  const $checkboxes = [...$form.querySelectorAll('input[type="checkbox"]')]

  if ($checkboxes.length > 0) {
    $checkboxes.map(($item: HTMLInputElement) => {
      $item.addEventListener('change', () => {
        $form.submit()
      })
    })
  }

  const $dropdowns = [...$form.querySelectorAll('.dropdown')]

  if ($dropdowns.length > 0) {
    $dropdowns.map(($item: HTMLElement) => {
      if ($item) {
        dropdown($item, () => $form.submit())
      }
    })
  }
}
