/***
 * @name Used Cars - component
 *
 */

import { apiGetUsedCars } from '@src/api/client'

import render from './render'
import { ISellerInfoItem } from './SellerInfoItem'

const CreateNewUsedCarBlock = ($root: HTMLElement) => {
  let count = 0
  if ($root) {
    const fetchUsedCars = async () => {
      try {
        const response = await apiGetUsedCars(
          $root.dataset.clientId,
          Number($root.dataset.limit),
          $root.dataset.model,
          Boolean($root.dataset.isPrivateLease)
        )

        const sellerInfo: ISellerInfoItem = {
          email: $root.dataset.sellerInfoEmail,
          imageUrl: $root.dataset.sellerInfoImageUrl || null,
          name: $root.dataset.sellerInfoName,
          phone: $root.dataset.sellerInfoPhone,
          position: $root.dataset.sellerInfoPosition,
          tagline: $root.dataset.sellerInfoTagline,
          title: $root.dataset.sellerInfoTitle,
        }

        if (response.data.count > 0) {
          $root.classList.add('has-min-height')
        }

        // If isn't filtered on model, recalculate the total count of all
        // occasions, also including other brands. Because the default
        // value of response.data.count only contains the Toyota occasions
        // and we want to show a counter of all occasions.
        if (response.data && !$root.dataset.model) {
          const brandFacet = response.data.facets.find((item) => item.name === 'Brand')
          if (brandFacet) {
            const brandCounts = brandFacet.options.map((item) => item.count)
            response.data.count = brandCounts.reduce((total, currentValue) => total + currentValue)
          }
        }

        render(
          $root,
          response.data,
          $root.dataset.dealerUrl,
          $root.dataset.isPrivateLease === 'True',
          sellerInfo.name ? sellerInfo : null,
          $root.dataset.sellerInfoShow === 'True'
        )

        if ('lazyLoadInstance' in window) {
          ;(window as any).lazyLoadInstance.update()
        }
      } catch (error) {
        count = count ? count + 1 : 1

        if (count < 5) {
          setTimeout(() => {
            fetchUsedCars()
          }, 500)
        }
      }
    }

    fetchUsedCars()
  }
}

//
;(() => {
  const $root = [...document.querySelectorAll('.js-used-cars')]
  $root.map(($item: HTMLElement) => CreateNewUsedCarBlock($item))
})()
