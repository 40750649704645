/**
 * @description ...
 *
 */

import dropdown from '@src/components/uikit/dropdown'

const $root: HTMLFormElement = document.querySelector('.js-form-jobs')

if ($root) {
  const $dropdown: HTMLElement = $root.querySelector('.dropdown')

  if ($dropdown) {
    dropdown($dropdown, () => $root.submit())
  }
}
