/***
 * @name Gallery - component
 *
 */

import render from './render'

// Local imports
const $root = [...document.querySelectorAll('.js-gallery')]

if ($root.length > 0) {
  $root.map(($item: HTMLDivElement) => render($item, $item.dataset.title, $item.dataset.target))
}
