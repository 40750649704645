/**
 * @description Filter list used in News & Promos List view
 *
 */

import dropdown from '@src/components/uikit/dropdown'
import { bodyScrollIntoView } from '@src/utils/scrollTo'

const $forms = [...document.querySelectorAll('.js-form-general')]

// Apologies - this was quick and dirty to get it done for the demo.
// Ideally this should be separated into separate functions that can be tested
if ($forms.length > 0) {
  $forms.map(($item: HTMLFormElement) => {
    const $dropdowns = [...$item.querySelectorAll('.dropdown')]

    if ($dropdowns.length > 0) {
      $dropdowns.map(($el: HTMLElement) => {
        if ($el) {
          dropdown($el)
        }
      })
    }

    const $inputsText = [...$item.querySelectorAll('.input-field input, .input-field textarea')]
    if ($inputsText.length > 0) {
      $inputsText.map(($el: HTMLInputElement) => {
        const $invalid: HTMLElement = $el.parentElement.parentElement

        if ($invalid.classList.contains('input-invalid')) {
          $invalid.addEventListener('keydown', () => {
            $invalid.classList.remove('input-invalid')
          })
        }
      })
    }

    const $inputCheckbox = [...$item.querySelectorAll('input[type=checkbox]')]
    $inputCheckbox.map(($el: HTMLInputElement) => {
      if ($el.parentElement.classList.contains('input-invalid')) {
        $el.addEventListener('change', () => {
          $el.parentElement.classList.remove('input-invalid')
        })
      }
    })

    const $inputRadio = [...$item.querySelectorAll('input[type=radio]')]
    $inputRadio.map(($el: HTMLInputElement) => {
      if ($el.parentElement.parentElement.classList.contains('input-invalid')) {
        $el.addEventListener('change', () => {
          $el.parentElement.parentElement.classList.remove('input-invalid')
        })
      }
    })

    const $btn: HTMLButtonElement = $item.querySelector('button[type=submit')
    if ($btn) {
      $item.addEventListener('submit', () => {
        $btn.disabled = true
      })
    }
  })

  // TODO: Add an offset to the bodyScrollIntoView so that we have a bit more space above
  const $firstInvalid: HTMLElement = document.querySelector('.input-invalid')
  if ($firstInvalid) {
    bodyScrollIntoView($firstInvalid)
  }
}
