import openIframe from '@src/utils/openIframe'

document.body.addEventListener(
  'click',
  (event) => {
    // find the closest parent of the event target that
    // matches the selector

    const closest = (event.target as Element).closest('.action-overlayer')
    if (closest instanceof HTMLAnchorElement) {
      event.preventDefault()
      const iframeUrl = encodeURIComponent(closest.href)
      ;(window as any).lastHash = window.location.hash.substring(1)
      openIframe(iframeUrl)
    }
  },
  true
)
