/***
 * @name Car Review - component
 *
 */

import { apiGet } from '@src/api/client'

import render from './render'

// Local imports
const $root: HTMLAnchorElement = document.querySelector('.js-car-reviews')

if ($root) {
  const fetchReviews = async () => {
    const response = await apiGet($root.dataset.apiUrl)

    render($root, response.data, $root.dataset.readMoreUrl)
  }

  fetchReviews()
}
