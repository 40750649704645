/**
 * @description Toggle component for team page
 *
 */

;(() => {
  const $items = [...document.querySelectorAll('.js-team-item')]

  let $open: HTMLElement

  $items.map(($item: HTMLElement) => {
    $item.addEventListener('click', () => {
      if ($open && $open !== $item) {
        $open.classList.remove('is-open')
      }

      if ($item.classList.contains('is-open')) {
        $item.classList.remove('is-open')

        $open = null
      } else {
        $item.classList.add('is-open')
        $open = $item
      }
    })
  })
})()
