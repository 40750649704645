/***
 * @name Car reviews - render component
 *
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import CarReviews from './CarReviews'

const renderComponent = ($container: HTMLElement, data: any, readMoreUrl: string) => {
  if ($container) {
    const root = createRoot($container)
    root.render(<CarReviews readMoreUrl={readMoreUrl} data={data} />)
  }
}

export default renderComponent
