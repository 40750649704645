/***
 * @name Used Cars - Teaser component
 *
 */

import React from 'react'

import { numberFormatter } from '@src/utils/number-formatter'
import SellerInfoItem, { ISellerInfoItem } from './SellerInfoItem'
import UsedCarItem, { IUsedCarItem } from './UsedCarItem'

interface IUsedCarData {
  count: number
  occasions: IUsedCarItem[]
}

interface IUsedCarTeaser {
  data: IUsedCarData
  dealerUrl: string
  isPrivateLease: boolean
  sellerInfo?: ISellerInfoItem
  showInfo: boolean
}

const UsedCarTeaser: React.FC<IUsedCarTeaser> = ({
  data,
  dealerUrl,
  isPrivateLease,
  sellerInfo,
  showInfo = true,
}) => {
  const { occasions, count } = data

  // Define CTA url with or without private lease filter
  const privateLeaseFilter = {
    filter: JSON.stringify({
      PriceLeasePilot: ['private lease'],
    }),
  }

  const ctaUrl = isPrivateLease
    ? `${dealerUrl}?${new URLSearchParams(privateLeaseFilter)}`
    : dealerUrl

  if (occasions.length > 0 && count > 0) {
    return (
      <>
        <div className="container">
          <div className="row row-flex flex-wrap">
            {occasions.length > 0 && (
              <div className="col-xs-12 col-sm-4 col-lg-3 order-sm-1">
                <UsedCarItem
                  dealerUrl={dealerUrl}
                  isPrivateLease={isPrivateLease}
                  {...occasions[0]}
                />
              </div>
            )}
            {occasions.length > 1 && (
              <div className="col-xs-12 col-sm-4 col-lg-3 order-sm-2">
                <UsedCarItem
                  dealerUrl={dealerUrl}
                  isPrivateLease={isPrivateLease}
                  {...occasions[1]}
                />
              </div>
            )}
            {occasions.length > 2 && (
              <div className="col-xs-12 col-sm-4 col-lg-3 order-sm-4 order-lg-3">
                <UsedCarItem
                  dealerUrl={dealerUrl}
                  isPrivateLease={isPrivateLease}
                  {...occasions[2]}
                />
              </div>
            )}
            {occasions.length > 3 && (
              <div className="col-xs-12 col-sm-4 col-lg-3 d-none d-sm-block order-sm-5 order-lg-5">
                <UsedCarItem
                  dealerUrl={dealerUrl}
                  isPrivateLease={isPrivateLease}
                  {...occasions[3]}
                />
              </div>
            )}
            {occasions.length > 4 && (
              <div className="col-xs-12 col-sm-4 col-lg-3 d-none d-sm-block order-sm-6 order-lg-6">
                <UsedCarItem
                  dealerUrl={dealerUrl}
                  isPrivateLease={isPrivateLease}
                  {...occasions[4]}
                />
              </div>
            )}
            {occasions.length > 5 && (
              <div className="col-xs-12 col-sm-4 col-lg-3 d-none d-lg-block order-lg-7">
                <UsedCarItem
                  dealerUrl={dealerUrl}
                  isPrivateLease={isPrivateLease}
                  {...occasions[5]}
                />
              </div>
            )}
            {occasions.length > 6 && (
              <div className="col-xs-12 col-sm-4 col-lg-3 d-none d-lg-block order-lg-8">
                <UsedCarItem
                  dealerUrl={dealerUrl}
                  isPrivateLease={isPrivateLease}
                  {...occasions[6]}
                />
              </div>
            )}

            {showInfo ? (
              <div className="col-xs-12 col-sm-4 col-lg-3 order-sm-3 order-lg-4">
                <SellerInfoItem {...sellerInfo} />
              </div>
            ) : (
              occasions.length > 7 && (
                <div className="col-xs-12 col-sm-4 col-lg-3 order-sm-3 order-lg-4">
                  <UsedCarItem
                    dealerUrl={dealerUrl}
                    isPrivateLease={isPrivateLease}
                    {...occasions[7]}
                  />
                </div>
              )
            )}
          </div>

          {ctaUrl && count > 0 && (
            <div className="block-used-cars__footer">
              <a href={ctaUrl} className="btn btn-simple">
                Alle occasions ({numberFormatter.format(count)})
              </a>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div className="block-used-cars__footer has-no-padding">
      <a href={ctaUrl} className="btn btn-simple">
        Alle occasions
      </a>
    </div>
  )
}

export default UsedCarTeaser
