/***
 * @name Used Cars - Item component
 *
 */

import React from 'react'

// TODO: This should be set externally in some sort of Settings object
// so it can be expanded to other locales
const FormatterCurrency = new Intl.NumberFormat('nl-NL', {
  currency: 'EUR',
  minimumFractionDigits: 0,
  style: 'currency',
})

const FormatterDistance = new Intl.NumberFormat('nl-NL', {
  minimumFractionDigits: 0,
  style: 'decimal',
})

const isApprovedOccasion = (year: number, mileage: number) => {
  return new Date().getFullYear() - year < 10 && mileage < 200_000
}

interface IUsedCarDealer {
  city: string
}

interface IUsedCarWarranty {
  type: string
  months: number
}

export interface IUsedCarItem {
  brand: string
  dealer?: IUsedCarDealer
  dealerUrl: string
  id: string
  imageUrls: string[]
  mileage: number
  model: string
  price: number
  priceLease?: number
  isPrivateLease: boolean
  type: string
  warranty?: IUsedCarWarranty
  year: number
}

//
const UsedCarItem: React.FC<IUsedCarItem> = ({
  brand,
  dealer,
  dealerUrl,
  id,
  imageUrls,
  mileage,
  model,
  price,
  priceLease,
  isPrivateLease,
  type,
  year,
}) => {
  const imageUrl = imageUrls && imageUrls.length ? `${imageUrls[0]}?w=705` : null
  return (
    <div className="item-used-car" itemType="http://schema.org/Car">
      {imageUrl && (
        <div className="item-used-car__image">
          <img src={imageUrl} alt={`${brand} ${model}`} loading="lazy" itemProp="image" />
        </div>
      )}
      <div className="item-used-car__body">
        <a href={`${dealerUrl}/auto/${id}`} className="item-used-car__link">
          <h5 itemProp="name" className="item-used-car__title">
            {brand} <span>{model}</span>
          </h5>
        </a>
        <p className="item-used-car__description" itemProp="description">
          {type}
        </p>
        <ul className="item-used-car__info">
          <li>
            <i className="icon icon-consumptions" />
            <span itemProp="mileageFromOdometer">{FormatterDistance.format(mileage)} km</span>
          </li>
          <li>
            <i className="icon icon-car" />
            <span itemProp="modelDate">{year}</span>
          </li>
          {dealer && dealer.city && (
            <li>
              <i className="icon icon-map-marker" />
              <span>{dealer.city}</span>
            </li>
          )}
        </ul>
        <ul className="item-used-car__footer">
          <li>
            {isPrivateLease ? (
              priceLease && (
                <h4
                  itemProp="priceSpecification"
                  itemScope={true}
                  itemType="http://schema.org/UnitPriceSpecification"
                >
                  <meta itemProp="priceCurrency" content="EUR" />
                  <meta itemProp="price" content={String(priceLease)} />
                  <meta itemProp="unitCode" content="MON" />
                  {FormatterCurrency.format(priceLease)},-
                  <span className="item-used-car__price--lease"> p/m</span>
                </h4>
              )
            ) : (
              <h4
                itemProp="priceSpecification"
                itemScope={true}
                itemType="http://schema.org/UnitPriceSpecification"
              >
                <meta itemProp="priceCurrency" content="EUR" />
                <meta itemProp="price" content={String(price)} />
                {FormatterCurrency.format(price)},-
              </h4>
            )}
          </li>
          {isApprovedOccasion(year, mileage) && (
            <li>
              <p className="item-used-car__guarantee">
                <img
                  src="/static/toyota/images/approved-occasions.png"
                  alt="Toyota Approved Occasions"
                />
              </p>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default UsedCarItem
