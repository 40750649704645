/***
 * @name Gallery - Sub navigation
 *
 */

import React from 'react'

export interface ISubNav {
  items: any[]
  selected?: string
  onSelected(id: string): void
}

const SubNav: React.FC<ISubNav> = ({ items, selected, onSelected }) => {
  const clickHandler = (e: any) => {
    e.preventDefault()

    if (onSelected) {
      const { href } = e.target
      const newSelected: string = href.substr(href.indexOf('#') + 1, href.length)
      if (newSelected !== selected) {
        onSelected(newSelected)
      }
    }
  }

  return items.length > 1 ? (
    <div className="block-car-model-gallery__subnav">
      <ul className="list is-subnav">
        {items.map((item, index) => (
          <li
            key={`item-${index}`}
            className={item.id === selected ? 'list__item is-active' : 'list__item'}
          >
            <a onClick={clickHandler} href={`#${item.id}`}>
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <></>
  )
}

export default SubNav
