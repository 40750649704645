import '@src/components/dealerwebsite/accordion'
import '@src/components/dealerwebsite/car-reviews'
import '@src/components/dealerwebsite/faq'
import '@src/components/dealerwebsite/form-filter'
import '@src/components/dealerwebsite/form-general'
import '@src/components/dealerwebsite/gallery'
import '@src/components/dealerwebsite/job-list'
import '@src/components/dealerwebsite/modal-appointment'
import '@src/components/dealerwebsite/overlay-cookiesettings'
import '@src/components/dealerwebsite/overlay-iframe'
import '@src/components/dealerwebsite/taxation'
import '@src/components/dealerwebsite/team'
import '@src/components/dealerwebsite/tooltip-promo'
import '@src/components/dealerwebsite/used-cars'
import '@src/components/uikit/header'
import '@src/utils/router'
import './message-handler'
