/***
 * @name Gallery - render component
 *
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import Main from './Main'

//
// == Global declaration for google API
declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    gallery_images: any
  }
}

const renderComponent = ($container: HTMLElement, title: string, target: string) => {
  if ($container && window.gallery_images && window.gallery_images[target]) {
    const root = createRoot($container)

    //
    // NOTE: I know this looks ugly buuuuut... I was told it's not performant for the backend to generate
    // a UUID for the image so instead I've used this.
    // Concatenate a unique ID for a lookup when the gallery is flattened
    const items = window.gallery_images[target].map((gallery: any) => {
      const temp = gallery.items.map((item: any, j: number) => {
        item.id = `${gallery.id}__image-${j}`
        item.galleryId = gallery.id

        return item
      })
      gallery.items = temp
      return gallery
    })

    root.render(<Main title={title} items={items} />)
  }
}

export default renderComponent
