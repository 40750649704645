/***
 * @name Used Cars - Related component
 *
 */

import React from 'react'

import { numberFormatter } from '@src/utils/number-formatter'
import ReviewSummaryRowItem from './ReviewSummaryRowItem'

export interface IReviewSummary {
  aspect1AverageScore: string
  aspect2AverageScore: string
  aspect3AverageScore: string
  aspect4AverageScore: string
  aspect5AverageScore: string
  averageScore: string
  averageStarRating: string
  brandCode: number
  model: string
  numberOfReviews: number
}

const ReviewSummaryItem: React.FC<IReviewSummary> = ({
  aspect1AverageScore,
  aspect2AverageScore,
  aspect3AverageScore,
  aspect5AverageScore,
  averageScore,
  numberOfReviews,
}) => {
  const value: number = parseInt(averageScore, 10) * 10

  return (
    <div className="item-review-summary">
      <h6 className="item-review-summary__title">Gemiddelde van alle reviews</h6>

      <div
        className="review-stars"
        itemScope={true}
        itemType="http://schema.org/AggregateRating"
        itemProp="aggregateRating"
      >
        <div className="review-stars__item">
          <div className="review-stars__item-value" style={{ width: `${value}%` }} />
        </div>
        <div className="review-stars__amount">{numberFormatter.format(+averageScore)}</div>
        <meta itemProp="ratingValue" content={averageScore} />
        <meta itemProp="reviewCount" content={String(numberOfReviews)} />
        <meta itemProp="worstRating" content="0" />
        <meta itemProp="bestRating" content="10" />
      </div>

      <ul className="review-summary">
        <ReviewSummaryRowItem label="Prijs/kwaliteit" score={aspect1AverageScore} />
        <ReviewSummaryRowItem label="Gebruiksgemak" score={aspect2AverageScore} />
        <ReviewSummaryRowItem label="Rijeigenschappen" score={aspect3AverageScore} />
        <ReviewSummaryRowItem label="Verbruik" score={aspect5AverageScore} />
      </ul>
    </div>
  )
}

export default ReviewSummaryItem
