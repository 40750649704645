/**
 * @name Dropdown
 *
 * @description
 *
 */

const dropdown = ($target: HTMLElement, onChanged: any = null) => {
  if ($target) {
    const $dropdownToggle: HTMLElement = $target.querySelector('.dropdown-toggle')
    const $dropdownToggleSelectedItem: HTMLElement = $dropdownToggle.querySelector('.selected-item')
    const $dropdownMenu: HTMLElement = $target.querySelector('.dropdown-menu')
    const inputTarget: string = $target.dataset.select
    const $inputTarget: HTMLInputElement = $target.querySelector(`input[name="${inputTarget}"]`)
    let $dropdownItemActive: HTMLElement

    const documentClickHandler = () => {
      clearEventHandlers()

      $target.classList.toggle('open')

      if ($target.classList.contains('open')) {
        requestAnimationFrame(setEventHandlers)
      } else {
        requestAnimationFrame(clearEventHandlers)
      }
    }

    const clearEventHandlers = () => {
      document.removeEventListener('click', documentClickHandler)
    }

    const setEventHandlers = () => {
      document.addEventListener('click', documentClickHandler)
    }

    if ($dropdownToggle && $dropdownMenu && $inputTarget) {
      // Set dropdown toggle event listener
      $dropdownToggle.addEventListener('click', (e) => {
        e.preventDefault()

        documentClickHandler()
      })

      // Set dropdown menu event listener
      $dropdownMenu.addEventListener('click', (e) => {
        e.preventDefault()

        if ($dropdownItemActive) {
          $dropdownItemActive.classList.remove('active')
        }

        $dropdownItemActive = e.target as HTMLElement

        const { value } = $dropdownItemActive.dataset
        $inputTarget.value = value
        $dropdownToggleSelectedItem.innerText = $dropdownItemActive.innerText

        $dropdownItemActive.classList.add('active')

        if ($target.parentElement.classList.contains('input-invalid')) {
          $target.parentElement.classList.remove('input-invalid')
        }

        if (onChanged !== null) {
          onChanged(value)
        }

        documentClickHandler()
      })
    }
  }
}

export default dropdown
