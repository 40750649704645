import cookie from 'cookiejs'
const setConsentCookie = () => {
  window.CookieBar.onConsent = () => {
    if (!window.CookieBar.consentLevel) {
      return
    }

    const consentCookie = cookie.get('consent')
    if (!consentCookie || consentCookie !== window.CookieBar.consentLevel) {
      document.cookie = 'consent=' + window.CookieBar.consentLevel
      if ('dataLayer' in window) {
        window.dataLayer.push({ event: 'consent' })
      }
    }

    const consentLevelInt = +window.CookieBar.consentLevel
    document.dispatchEvent(
      new CustomEvent('onConsent', { detail: { consentLevel: consentLevelInt } })
    )

    // Store also in window.T1.settings so we can access it in cookiesettings react file.
    window.T1 = window.T1 || {}
    window.T1.settings = window.T1.settings || {}
    window.T1.settings.consentLevel = consentLevelInt
  }
}

export { setConsentCookie }
