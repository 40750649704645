/***
 * @name Car Reviews - Review detail Item
 *
 */

import React from 'react'

import openIframe from '@src/utils/openIframe'

export interface IReviewDetail {
  customerName: string
  externalId: string
  openAnswer: string
  productReviewId: number
  reviewDate: string
  score: string
  starRating: string
  readMoreUrl: string
}

const ReviewDetailItem: React.FC<IReviewDetail> = ({
  customerName,
  openAnswer,
  reviewDate,
  score,
  readMoreUrl,
}) => {
  const clickHandler = (e: any) => {
    e.preventDefault()

    const iframeUrl = encodeURIComponent(e.currentTarget.href)
    openIframe(iframeUrl)
  }

  const value: number = parseInt(score, 10) * 10

  return (
    <div
      className="item-review-detail"
      itemProp="review"
      itemScope={true}
      itemType="http://schema.org/Review"
    >
      <div
        className="review-stars"
        itemProp="reviewRating"
        itemScope={true}
        itemType="http://schema.org/Rating"
      >
        <div className="review-stars__item">
          <div className="review-stars__item-value" style={{ width: `${value}%` }} />
        </div>
        <meta itemProp="ratingValue" content={String(score)} />
        <meta itemProp="worstRating" content="0" />
        <meta itemProp="bestRating" content="10" />
      </div>

      <p className="item-review-detail__description" itemProp="reviewBody">
        <q>{openAnswer}</q>
      </p>

      <p className="item-review-detail__author">
        {customerName}
        <br />
        <span>{reviewDate}</span>
        <meta itemProp="author" content={customerName} />
        <meta itemProp="datePublished" content={reviewDate} />
      </p>

      <a href={readMoreUrl} className="item-review-detail__link" onClick={clickHandler}>
        <span>Lees meer</span>
      </a>
    </div>
  )
}

export default ReviewDetailItem
