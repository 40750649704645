/***
 * @name Used Cars - Related component
 *
 */

import React from 'react'

import ReviewDetailItem from './ReviewDetailItem'
import ReviewSummaryItem, { IReviewSummary } from './ReviewSummaryItem'

interface IReviewData extends IReviewSummary {
  productReviews: []
}

interface ICarReviewsData {
  data: IReviewData
  readMoreUrl: string
}

const CarReviews: React.FC<ICarReviewsData> = ({ data, readMoreUrl }) => {
  const { model, numberOfReviews, productReviews } = data

  if (numberOfReviews > 0) {
    return (
      <>
        <h1 className="">Klantreviews Toyota {model}</h1>
        <p className="block-car-model-reviews__subtitle">
          <span>{numberOfReviews}</span> beoordelingen
        </p>

        <div className="row row-flex flex-wrap">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <ReviewSummaryItem {...data} />
          </div>
          {productReviews.map((item: any, index: number) => (
            <div key={`item-${index}`} className="col-xs-12 col-sm-6 col-md-3">
              <ReviewDetailItem readMoreUrl={readMoreUrl} {...item} />
            </div>
          ))}
        </div>
      </>
    )
  }

  return <></>
}

export default CarReviews
