/**
 * @class Viewport utility
 *
 * @description Wrapper for window.resize with custom trigger event
 * Calculates the true height of a window using the browser variable '--vh'
 *
 * Taken from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */

import { debounce } from 'ts-debounce'

// Export event name and resize data object
export const RESIZE: string = 'window::resized'
export const BREAKPOINT_CHANGE: string = 'window::breakpoint-changed'

export const BREAKPOINT_DESKTOP: string = 'lg'
export const BREAKPOINT_MOBILE: string = 'xs-md'

export interface IViewportResized {
  width: number
  height: number
}

interface IFunction {
  apply(context: any, ...params: any[]): void
}

const { body } = document
const BREAKPOINT_DESKTOP_PX: number = 1200

class Viewport {
  private events: any
  private enabled: boolean = false
  private windowWidth: number
  private breakpoint: string

  constructor() {
    this.events = {}
    this.windowWidth = window.innerWidth
    this.breakpoint =
      this.windowWidth >= BREAKPOINT_DESKTOP_PX ? BREAKPOINT_DESKTOP : BREAKPOINT_MOBILE

    this.setEnabled(true)
  }

  private _onResize = debounce(this.onResize.bind(this), 50)

  // Set enabled
  public setEnabled(value: boolean): void {
    if (this.enabled !== value) {
      this.enabled = value

      window.removeEventListener('resize', this._onResize)

      if (this.enabled) {
        window.addEventListener('resize', this._onResize)
        this.onResize(false)
      }
    }
  }

  public addListener(eventType: string, listener: any): void {
    if (!this.events[eventType]) {
      this.events[eventType] = { listeners: [] }
    }
    this.events[eventType].listeners.push(listener)
  }

  public dispatchEvent(eventType: string, ...args: any[]): void {
    const event = this.events[eventType]

    if (event && event.listeners) {
      const { listeners } = event
      if (listeners) {
        listeners.map((listener: IFunction) => listener.apply(this, args))
      }
    }
  }

  // Private event handler
  private onResize(triggerEvent: boolean = true): void {
    const vh: number = window.innerHeight * 0.01

    body.style.setProperty('--vh', `${vh}px`)

    if (triggerEvent) {
      const width: number = window.innerWidth
      const height: number = window.innerHeight
      const response: IViewportResized = { width, height }

      const currBreakpoint: string =
        width >= BREAKPOINT_DESKTOP_PX ? BREAKPOINT_DESKTOP : BREAKPOINT_MOBILE

      // TODO: We reaaally should be putting this into a separate function and passing in a dictionary of
      // breakpoints but alas there is no time...
      if (
        this.breakpoint !== currBreakpoint &&
        response.width < BREAKPOINT_DESKTOP_PX &&
        this.windowWidth >= BREAKPOINT_DESKTOP_PX
      ) {
        this.breakpoint = BREAKPOINT_MOBILE
        this.dispatchEvent(BREAKPOINT_CHANGE, this.breakpoint)
      } else if (
        this.breakpoint !== currBreakpoint &&
        response.width >= BREAKPOINT_DESKTOP_PX &&
        this.windowWidth < BREAKPOINT_DESKTOP_PX
      ) {
        this.breakpoint = BREAKPOINT_DESKTOP
        this.dispatchEvent(BREAKPOINT_CHANGE, this.breakpoint)
      }

      this.windowWidth = width

      this.dispatchEvent(RESIZE, response)
    }
  }
}

const viewport = new Viewport()

export default viewport
