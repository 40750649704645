import { hide } from '@src/components/dealerwebsite/overlay/render'

function tryJSONParse(json: string) {
  try {
    return JSON.parse(json)
  } catch {
    return null
  }
}

window.addEventListener(
  'message',
  (event) => {
    const result = tryJSONParse(event.data)
    if (result) {
      if (result.channel === 'com.toyota.tme.postmessage') {
        if (result.topic === 'alcredisfs.configurator.close') {
          hide()
        }
      }
    }
  },
  false
)
