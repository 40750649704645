/***
 * @name Gallery - Gallery (duh!)
 *
 */

import React from 'react'

//
// Interface
export interface IGallery {
  id: string
  items: any[]
  labelMore: string
  onSelected(id: string): void
}

//
// Instance
const Gallery: React.FC<IGallery> = ({ id, items, labelMore, onSelected }) => {
  //
  // For our gallery we want to limit the number of elements to 5
  const data: any[] = items.length > 5 ? items.slice(0, 5) : items
  const length: number = data.length
  const className: string = length < 5 ? `gallery has-${length}-images` : 'gallery has-5-images'

  //
  // Event handler
  const imageClickHandler = (event: any) => {
    event.preventDefault()

    if (onSelected) {
      const imageId: string = (event.currentTarget as HTMLElement).dataset.id as string

      onSelected(`${id}__image-${imageId}`)
    }
  }

  //
  // Render
  return (
    <div className={className}>
      {data.map((item: any, index: number) => (
        <a
          key={`item-${index}`}
          href="#"
          className="gallery__link"
          data-id={index}
          data-label={length > 1 && index + 1 === length && `${labelMore} (${items.length})`}
          onClick={imageClickHandler}
        >
          <img src={item.imagePreviewUrl} alt={item.alt} />
        </a>
      ))}
    </div>
  )
}

// Only update when inputs change
export default React.memo(Gallery)
