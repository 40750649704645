/***
 * @name Overlay - React component
 *
 */

import React, { useEffect, useState } from 'react'

import scrollLock from '@src/utils/scrollLock'

interface IOverlay {
  show?: boolean
  type?: string
  icon?: string
  onClose?(): void
  children?: React.ReactNode
}

// Transition constants
const TRANSITION_PROGRESS: number = 1
const TRANSITION_IN_COMPLETE: number = 2
const TRANSITION_OUT_COMPLETE: number = 3

// TODO: Use hook for previous value
let prevShow: boolean = null
const $root: HTMLElement = document.querySelector('.js-overlay')

//
//
const Overlay: React.FC<IOverlay> = ({
  show,
  children,
  type = 'is-iframe',
  icon = 'icon-remove',
  onClose,
}) => {
  //
  // State
  const [showButton, setShowButton] = useState(false)
  const [showBody, setShowBody] = useState(false)
  const [showBackground, setShowBackground] = useState(false)
  const [transition, setTransition] = useState(TRANSITION_OUT_COMPLETE)

  //
  // Methods
  const transitionIn = () => {
    scrollLock($root, true, 'is-locked')

    setTransition(TRANSITION_PROGRESS)

    setTimeout(() => {
      setShowBackground(true)
    }, 25)

    setTimeout(() => {
      setShowBody(true)
    }, 500)

    setTimeout(() => {
      setShowButton(true)
    }, 500)

    setTimeout(() => {
      setTransition(TRANSITION_IN_COMPLETE)
    }, 1025)
  }

  const transitionOut = () => {
    setTransition(TRANSITION_PROGRESS)

    setShowButton(false)

    setTimeout(() => {
      setShowBody(false)
    }, 50)

    setTimeout(() => {
      setShowBackground(false)
    }, 300)

    setTimeout(() => {
      setTransition(TRANSITION_OUT_COMPLETE)
    }, 1000)
  }

  //
  // Hook
  useEffect(() => {
    if (prevShow !== show) {
      prevShow = show

      if (show) {
        transitionIn()
      } else {
        transitionOut()
      }
    }
  }, [show])

  //
  // Event handler
  // We add support for a close callback in case we don't want to use the router
  const clickCloseHandler = (event: any) => {
    if (onClose) {
      event.preventDefault()

      onClose()
    }
  }

  //
  // Render
  if (!show && transition === TRANSITION_OUT_COMPLETE) {
    scrollLock($root, false, 'is-locked')
    return <></>
  }

  return (
    <div className={`overlay ${type}`}>
      <div
        className={showBackground ? 'overlay__background show-background' : 'overlay__background'}
      />
      <div className={showBody ? 'overlay__body show-body' : 'overlay__body'}>{children}</div>
      <a
        href="#"
        className={showButton ? 'overlay__btn-close show-button' : 'overlay__btn-close'}
        onClick={clickCloseHandler}
      >
        <i className={`icon ${icon}`} />
      </a>
    </div>
  )
}

export default Overlay
