/**
 * @description Dealerwebsites - Faq
 *
 */

;(() => {
  const $items = [...document.querySelectorAll('.js-faq-question')]

  $items.map(($item) => {
    $item.addEventListener('click', (event) => {
      event.preventDefault()

      // Ensure other questions are closed
      $items.map(($otherItem) => {
        if ($item !== $otherItem) {
          const $itemParent = $otherItem.parentElement
          const $itemAnswer = $otherItem.nextElementSibling as HTMLElement

          if ($itemParent.classList.contains('expanded')) {
            $itemParent.classList.remove('expanded')
            $itemAnswer.style.maxHeight = null
          }
        }
      })

      const $parent = $item.parentElement
      const $answer = $item.nextElementSibling as HTMLElement

      // Toggle question visibility
      if ($parent.classList.contains('expanded')) {
        $parent.classList.remove('expanded')
        $answer.style.maxHeight = '0'
        // $answer.style.display = 'none'
      } else {
        $parent.classList.add('expanded')
        $answer.style.maxHeight = `${$answer.scrollHeight + 17}px`
        // $answer.style.display = 'block'
      }
    })
  })
})()
