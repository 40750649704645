import * as BodyScrollLock from 'body-scroll-lock'

// Project constants
const { body } = document

const documentWidth = document.documentElement.clientWidth
const windowWidth = window.innerWidth
const scrollBarWidth = windowWidth - documentWidth

let sY = window.scrollY

const IS_IOS =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.platform &&
  /iP(ad|hone|od)/.test(window.navigator.platform)

const scrollLock = (target: HTMLElement, value: boolean, lockedClassName: string) => {
  const SCROLL_LOCKED = body.classList.contains(lockedClassName)

  if (value === SCROLL_LOCKED) {
    return
  }

  if (value) {
    if (target && IS_IOS) {
      BodyScrollLock.disableBodyScroll(target)
    }

    sY = window.scrollY
    body.classList.add(lockedClassName)
    body.style.top = `-${sY}px`

    body.style.setProperty('--scrollbar-width', `${scrollBarWidth}px`)
  } else {
    if (IS_IOS) {
      BodyScrollLock.clearAllBodyScrollLocks()
    }

    body.style.top = null

    body.classList.remove(lockedClassName)

    // iOS device needs to reset immediately
    if (IS_IOS) {
      window.scrollTo(0, sY)
    }

    // Reset after 1 frame
    requestAnimationFrame(() => {
      window.scrollTo(0, sY)
    })
  }
}

export default scrollLock
