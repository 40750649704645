/**
 * @description Dealerwebsites - Accordion
 *
 */

;(() => {
  const $items = [...document.querySelectorAll('.js-accordion-link')]

  $items.map(($item: HTMLButtonElement) => {
    $item.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault()

      const $accordion: HTMLInputElement = document.querySelector($item.dataset.accordionTarget)

      if ($accordion) {
        if ($accordion.classList.contains('is-accordion-visible')) {
          $accordion.classList.remove('is-accordion-visible')
        } else {
          $accordion.classList.add('is-accordion-visible')
        }
      }
    })
  })
})()
