/***
 * @name Used Cars - Related component
 *
 */

import React from 'react'

import { numberFormatter } from '@src/utils/number-formatter'
import UsedCarItem, { IUsedCarItem } from './UsedCarItem'

interface IUsedCarData {
  count: number
  occasions: IUsedCarItem[]
}

interface IUsedCarRelated {
  data: IUsedCarData
  dealerUrl: string
}

const UsedCarRelated: React.FC<IUsedCarRelated> = ({ data, dealerUrl }) => {
  const { occasions, count } = data

  if (occasions.length > 0) {
    return (
      <>
        <div className="row row-flex flex-wrap">
          {occasions.length > 0 && (
            <div className="col-xs-12 col-sm-4 col-lg-3">
              <UsedCarItem dealerUrl={dealerUrl} {...occasions[0]} />
            </div>
          )}
          {occasions.length > 1 && (
            <div className="col-xs-12 col-sm-4 col-lg-3">
              <UsedCarItem dealerUrl={dealerUrl} {...occasions[1]} />
            </div>
          )}
          {occasions.length > 2 && (
            <div className="col-xs-12 col-sm-4 col-lg-3 d-none d-sm-block">
              <UsedCarItem dealerUrl={dealerUrl} {...occasions[2]} />
            </div>
          )}
          {occasions.length > 3 && (
            <div className="col-xs-12 col-md-3 d-none d-lg-block">
              <UsedCarItem dealerUrl={dealerUrl} {...occasions[3]} />
            </div>
          )}
        </div>

        {dealerUrl && count > 0 && (
          <div className="block-used-cars__footer">
            <a
              href={`${dealerUrl}?model=${occasions[0].model.toLowerCase()}`}
              className="btn btn-simple"
            >
              Alle {occasions[0].model || ''} occasions ({numberFormatter.format(count)})
            </a>
          </div>
        )}
      </>
    )
  }

  return (
    <div className="block-used-cars__footer has-no-padding">
      <a href={dealerUrl} className="btn btn-simple" target="_self" rel="noopener">
        Alle occasions
      </a>
    </div>
  )
}

export default UsedCarRelated
