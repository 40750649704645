declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    CookieBar: {
      consentLevel: number
      submitConsent: (level: number) => void
    }
    siteConfig: any
  }
}

import React, { useEffect, useState } from 'react'
import cookie from 'cookiejs'
import { hide } from '@src/components/dealerwebsite/overlay/render'

const CookieSettings: React.FC = () => {
  const [cookieLevel, setCookieLevel] = useState(
    window.CookieBar?.consentLevel || +cookie.get('consent') || 40
  )

  const saveCookieLevelClickHandler = () => {
    if (window.CookieBar) {
      window.CookieBar.submitConsent(cookieLevel)
      hide()
    }
  }

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const consentLevel = +e.target.value
    if (e.target.checked) {
      setCookieLevel(consentLevel)
    } else {
      if (consentLevel === 40) {
        setCookieLevel(30)
      } else if (consentLevel === 30) {
        setCookieLevel(10)
      }
    }
  }

  useEffect(() => {
    if (!window.CookieBar) return

    // Add listener to listen to consent changed
    document.addEventListener('onConsent', (event: CustomEvent) => {
      if ('consentLevel' in event.detail) {
        const consentLevel = +event.detail.consentLevel
        setCookieLevel(consentLevel)
      }
    })

    // Set consent level if its a known value from cookie or from window
    const consentLevel =
      // Get value from cookie
      +cookie.get('consent') ||
      // Get value from cookiebar
      window.CookieBar.consentLevel ||
      // Get value from window t1 settings
      window.T1.settings.consentLevel

    if (consentLevel) {
      setCookieLevel(consentLevel)
    }
  }, [])

  // const name = window.siteConfig?.settings?.name ?? 'Toyota'

  return (
    <section className="ePrivacy">
      <div className="material-box-content">
        <div id="ePrivacy" className="gdpr container">
          <header>
            <h1 className="text-left">Toyota cookiebeleid</h1>
            <p>
              Hieronder ziet u uw huidige cookie instellingen. Om deze instellingen te behouden
              tijdens uw bezoek aan deze website klikt u op de knop ‘Doorgaan’. Wanneer u uw cookie
              instellingen heeft gewijzigd in onderstaande tabel kunt u klikken op de knop ‘Opslaan
              en doorgaan’.
            </p>
          </header>

          <div className="row">
            <ul id="cookie-specs" className="no-flex">
              <li className="col-xs-12 col-md-4" data-eprivacy-level="10">
                <div className="cookie-spec disabled">
                  <header className="form">
                    <input
                      type="checkbox"
                      name="necessaryBox"
                      id="necessaryBox"
                      value="10"
                      disabled={true}
                      defaultChecked={true}
                    />
                    <label className="check" htmlFor="necessaryBox">
                      <strong>Essentiële cookies</strong>
                    </label>
                  </header>
                  <ul className="actions">
                    <li className="icon ty-icon">
                      <p>Uw samengestelde auto's opslaan</p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="col-xs-12 col-md-4" data-eprivacy-level="30">
                <div className={cookieLevel >= 30 ? 'cookie-spec' : 'cookie-spec is-disabled'}>
                  <header className="form">
                    <input
                      type="checkbox"
                      name="functionalBox"
                      id="functionalBox"
                      value="30"
                      checked={cookieLevel >= 30}
                      onChange={inputChangeHandler}
                    />
                    <label className="check" htmlFor="functionalBox">
                      <strong>Functionele cookies</strong>
                    </label>
                  </header>
                  <ul className="actions">
                    <li className="icon ty-icon">
                      <p>Zorgen dat de pagina's optimaal worden weergegeven</p>
                    </li>
                    <li className="icon ty-icon">
                      <p>Anonieme statistieken bijhouden voor het verbeteren van de website</p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="col-xs-12 col-md-4" data-eprivacy-level="40">
                <div className={cookieLevel >= 40 ? 'cookie-spec' : 'cookie-spec is-disabled'}>
                  <header className="form">
                    <input
                      type="checkbox"
                      name="targetingBox"
                      id="targetingBox"
                      value="40"
                      checked={cookieLevel >= 40}
                      onChange={inputChangeHandler}
                    />
                    <label className="check" htmlFor="targetingBox">
                      <strong>Marketingcookies</strong>
                    </label>
                  </header>
                  <ul className="actions">
                    <li className="icon ty-icon">
                      <p>
                        De informatie uit deze marketingcookies kunnen wij met elkaar combineren
                      </p>
                    </li>
                    <li className="icon ty-icon">
                      <p>
                        Dit doen we zodat wij u op basis van uw online surf-, zoek- en koop-gedrag
                        zo relevant mogelijke aanbiedingen kunnen doen.{' '}
                      </p>
                    </li>
                    <li className="icon ty-icon">
                      <p>
                        Met deze marketing cookies houden we ook bij welke advertenties u hebt
                        gezien
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="col-xs-12 btns">
                <footer className="fixed-line-height text-left">
                  <a
                    href="#"
                    className="btn-dark btn btn-action-primary"
                    onClick={saveCookieLevelClickHandler}
                  >
                    <strong>Opslaan</strong>
                  </a>
                </footer>
              </li>
            </ul>
          </div>
          <header>
            <p>
              Op deze website gebruiken we cookies. Een cookie is een klein bestand dat verstuurd
              wordt door een internetserver en dat op de vaste schijf van uw computer wordt
              geplaatst. Een cookie houdt bijvoorbeeld gegevens bij van de wijze waarop u onze
              website bezoekt en bevat een aantal gegevens over uw bezoek. De meeste cookies zorgen
              ervoor dat onze website goed werkt en gebruiksvriendelijk is.
            </p>
            <p>
              Op onze website maken wij gebruik van allerlei soorten cookies. Onze cookies houden de
              volgende gegevens bij:
            </p>
            <p>
              - Uw voorkeuren en taalinstellingen van uw webbrowser;
              <br />
              - specifieke gegevens om uw bezoek aan de website mogelijk te maken;
              <br />
              - informatie over de webpagina’s die u geraadpleegd hebt om naar onze website te gaan;
              <br />
              - informatie over uw klikgedrag op de website en het gebruik van de website;
              <br />- gegevens die door uw browser verstuurd worden, zodat wij de inhoud en de
              presentatie van onze website aan kunnen passen op basis van uw browsertype.
            </p>
            <p>
              Deze gegevens worden gebruikt om onze website te verbeteren. Ook worden deze gegevens
              gebruikt voor marktonderzoek en marketingdoeleinden, zoals het plaatsen van
              advertenties.
            </p>
            <p>
              Sommige cookies worden overigens geplaatst zonder uw toestemming, omdat die cookies
              technisch noodzakelijk zijn om het bezoek aan onze website mogelijk te maken
              (zogeheten essentiële cookies).
            </p>
            <p>
              Voor andere niet-essentiële cookies vragen wij uw toestemming. Deze toestemming wordt
              verleend door het accepteren van onze cookiewall.
            </p>
          </header>
        </div>
      </div>
    </section>
  )
}

export default CookieSettings
