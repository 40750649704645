/***
 * @name Modal appointment - fetchDealers
 *
 */

import { apiGetDealers } from '@src/api/client'
import dropdown from '@src/components/uikit/dropdown'
import { IDealerLocation } from '@src/api/types'

//
// Local methods
const renderLocations = (dealers: IDealerLocation[] = []): string =>
  dealers
    .map(
      (item) =>
        `<li><a href="#" data-value="${item.cartel_id}">${item.name}</a><span class="icon icon-ok"></span></li>`
    )
    .join('')

const fetchDealers = async (url: string, $modal: HTMLElement, onChange: (v: string) => void) => {
  const response = await apiGetDealers(url)

  if (response && response.locations) {
    const locations = response.locations.filter((value) => value.cartel_id)

    locations.sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0))

    if (locations.length > 0) {
      $modal.querySelector('.dropdown-menu').innerHTML = renderLocations(locations)

      const $dropdown: HTMLElement = $modal.querySelector('.dropdown')
      if ($dropdown) {
        dropdown($dropdown, (value: string) => onChange(value))
      }
    } else {
      const $form: HTMLElement = document.querySelector('.form-appointment')
      $form.style.display = 'none'

      const $modalBody: HTMLElement = document.querySelector('.modal-body')
      $modalBody.innerHTML = `<p>${$modalBody.dataset.emptyWarning}</p>`

      const $modalFooter: HTMLElement = document.querySelector('.modal-footer')
      $modalFooter.style.display = 'none'
    }
  }
}

export default fetchDealers
